import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import _ from 'lodash';
import { searchInObject } from 'axl-js-utils';

import { DEFAULT_TRANSLATE_SOURCE_LANGUAGE, DEFAULT_TRANSLATE_TARGET_LANGUAGE } from '../constants/messenger';

const DEFAULT_TRANSLATE_OPTION = { sourceLanguage: DEFAULT_TRANSLATE_SOURCE_LANGUAGE, targetLanguage: DEFAULT_TRANSLATE_TARGET_LANGUAGE, enableTranslate: false };

class MessengerStore {
  constructor(api, assignmentStore) {
    makeObservable(this, {
      token: observable,
      upserting: observable,
      revoketing: observable,
      dateRanger: observable,
      startDate: observable,
      endDate: observable,
      limitMsg: observable,
      posting: observable,
      loading: observable,
      uploading: observable,
      topicLoading: observable,
      topicsLoading: observable,
      refreshTopicLoading: observable,
      isFollow: observable,
      following: observable,
      creatingTopic: observable,
      closing: observable,
      opening: observable,
      marking: observable,
      userListing: observable,
      newMessageId: observable,
      topicSelected: observable,
      topicSelectedId: observable,
      filesUploaded: observable,
      messengers: observable,
      followers: observable,
      unFollowers: observable,
      administrators: observable,
      markedAllViewed: observable,
      pageNumber: observable,
      pageSize: observable,
      messagesEmbedded: observable,
      refType: observable,
      admins: observable,
      drivers: observable,
      dispatchers: observable,
      driverSeaching: observable,
      driverSearchResult: observable,
      newMessage: observable,
      lastMessage: observable,
      topics: observable,
      topicsFilter: observable,
      topicsOderBy: observable,
      assignmentInfoInTopicSelected: observable,
      assignmentSummaries: observable,
      assignmentSummaryLoading: observable,
      AASummeriesQuery: observable,
      activeAssignmentSummaries: observable,
      loadingactiveAssignmentSummaries: observable,
      AA_Query: observable,
      AA_Following: observable,
      AA_Attended: observable,
      AA_ReadUnattended: observable,
      AA_Unattended: observable,
      AA_UnreadUnattended: observable,
      AA_FollowLoading: observable,
      AA_AttendedLoading: observable,
      AA_UnattendedLoading: observable,
      AA_ReadUnattendedLoading: observable,
      AA_UnreadUnattendedLoading: observable,
      subscribed: observable,
      subscribe: action,
      resetSelectedTopic: action,
      upsertToken: action,
      revokeToken: action,
      loadSingleTopicById: action,
      loadSingleTopicByRefId: action,
      loadTopics: action,
      topicQuery: observable,
      loadTopic: action,
      loadMessageByTopicId: action,
      generateTopic: action,
      postMessage: action,
      loadTopicByAssignmentId: action,
      loadSingleTopic: action,
      reloadMessages: action,
      uploadFile: action,
      removeUploadFiles: action,
      follow: action,
      forceFollow: action,
      forceUnfollow: action,
      driverFollow: action,
      driverUnfollow: action,
      unfollow: action,
      assignFollower: action,
      closeTopic: action,
      openTopic: action,
      getDispatchers: action,
      getAdmins: action,
      getDrivers: action,
      users: observable,
      getAdminDispatcher: action,
      getDriverInfo: action,
      loadingDriver: observable,
      getDriverByIds: action,
      setDate: action,
      setmarkedAllViewed: action,
      markAllViewed: action,
      assignmentConverstationSummary: action,
      setActiveAssignmentQuery: action,
      activeAssignmentSummery: action,
      assignmentLoading: observable,
      getAssignmentInfo: action,
      getFollowActiveAssignment: action,
      getAttendedActiveAssignment: action,
      getReadUnattendedActiveAssignment: action,
      getUnreadUnattendedActiveAssignment: action,
      getAllUnattendedActiveAssignment: action,
      generalSolving: observable,
      generalSolvingLoading: observable,
      generalSolved: observable,
      generalSolvedLoading: observable,
      generalUnsolve: observable,
      generalUnsolveLoading: observable,
      GS_Query: observable,
      setGeneralSupportQuery: action,
      getGeneralSupportQuery: action,
      getGeneralSolving: action,
      getGeneralSolved: action,
      getGeneralUnsolved: action,
      solving: observable,
      solve: action,
      unsolve: action,
      externaLloadTopic: action,
      generalSearching: observable,
      generalSearchQuery: observable,
      generalSearch: action,
      activeSearching: observable,
      activeSearchQuery: observable,
      activeSearch: action,
      logging: observable,
      activities: observable,
      activityQuery: observable,
      getActivityLogs: action,
      showingStopTypes: observable,
      filter: observable,
      filteredShowingStops: computed,
      stopSelected: observable,
      setStopSelected: action,
      addressSearchResults: observable,
      addressSearchFilter: observable,
      searchAddress: action,
      pods: observable,
      loadPodsByStop: action,
      sendLink: action,
      removeMessage: action,
      selectedDriver: computed,
      translateOption: observable,
      translateOptionMap: observable,
      setTranslateOption: action,
      resetTranslateOption: action,
      setSelectedAssignment: action,
    });

    this.api = api;
    this.processAssignmentDetail = assignmentStore.processAssignmentDetail;
    this.selectedAssignmentId = assignmentStore.selectedAssignmentId || null;
    this.selectedAssignment = assignmentStore.selectedAssignment || null;
    assignmentStore && assignmentStore.selectedAssignmentId && console.log(assignmentStore.selectedAssignmentId)
  }

  token = null;
  upserting = false;
  revoketing = false;
  dateRanger = 10;
  startDate = '';
  endDate = '';
  limitMsg = null;
  posting = false;
  loading = false;
  uploading = false;
  topicLoading = false;
  topicsLoading = false;
  refreshTopicLoading = false;
  isFollow = false;
  following = false;
  creatingTopic = false;
  closing = false;
  opening = false;
  marking = false;
  userListing = false;
  newMessageId = null;
  topicSelected = null;
  topicSelectedId = null;
  filesUploaded = [];
  messengers = [];
  followers = [];
  unFollowers = [];
  administrators = [];
  markedAllViewed = false;
  pageNumber = 1;
  pageSize = 10;
  messagesEmbedded = false;
  refType = 'ASSIGNMENT_CONVERSATION';
  // Store Users
  admins = [];
  drivers = [];
  dispatchers = [];
  // Driver process
  driverSeaching = false;
  driverSearchResult = null;
  // Message process
  newMessage = false;
  lastMessage = null;
  // Topics
  topics = [];
  topicsFilter = {
    "ref_type": "DRIVER_GENERAL_SUPPORT",
    "show_only_followed": false,
    "page_number": 1,
    "page_size": 2
  };
  topicsOderBy = {
    'age_in_milliseconds': 'asc'
  };
  assignmentInfoInTopicSelected = {};
  // Assignment Converstation
  assignmentSummaries = [];
  assignmentSummaryLoading = false;
  // Active Assignment Summeries
  AASummeriesQuery = {};
  activeAssignmentSummaries = {};
  loadingactiveAssignmentSummaries = false;
  // Active Assignment Sections
  AA_Query = {
    'start_ts': '',
    'end_ts': '',
    'page_number': 1,
    'page_size': 3,
  };
  AA_Following = [];
  AA_Attended = [];
  AA_ReadUnattended = [];
  AA_Unattended = [];
  AA_UnreadUnattended = [];
  AA_FollowLoading = false;
  AA_AttendedLoading = false;
  AA_UnattendedLoading = false;
  AA_ReadUnattendedLoading = false;
  AA_UnreadUnattendedLoading = false;

  subscribed = false;
  translateOption = DEFAULT_TRANSLATE_OPTION;
  translateOptionMap = {};

  async subscribe(cb) {
    const response = await this.api.post(`/messenger/subscribe_all`);
    if(response.status === 204 && response.ok) {
          this.subscribed = true;
     }
  }

  resetSelectedTopic() {
    this.topicSelected = null;
    this.topicSelectedId = null;
  }

  async upsertToken(token, cb) {
    if(!token) return;
    this.upserting = true;

    await this.api.post(`/fcm/upsert`, {
      "token": token,
      "os": "Windows",
      "device_id": null,
      "revoke_policy": "GLOBAL"
    }).then(res => {
      runInAction(()=>{
        this.upserting = false;
      })
      if(res.status === 204 || res.ok) {
        this.token = token;
        if(cb) cb(res);
      }}
    );
  }

  // Todo revokeToken()
  revokeToken() {
    this.revoketing = true;
    this.api.post(`/fcm/upsert`, {
      "token": null,
      "os": navigator.userAgent,
      "device_id": null,
      "revoke_policy": "GLOBAL"
    }).then(res => {
        if(res.status === 200 || res.ok) {
          this.token = null;
        }
        this.revoketing = false;
      }
    );
  }

  //Todo loadSingleTopicById()
  loadSingleTopicById(cb) {
    if(!this.topicSelected && !this.topicSelectedId) return false;

    const topicId = this.topicSelectedId || (this.topicSelected && this.topicSelected.id);
    this.loading = true;
    const params = new URLSearchParams();
    params.append('messages_embedded', this.messagesEmbedded);

    return this.api.get(`messenger/topics/${topicId}?${params}`).then(res => {
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
        this.topicSelectedId = topicId;
        this.messengers = res.data.messages;
        if(this.topicSelected && this.topicSelected.unviewed_messages_count) {
          this.markedAllViewed = false;
        }
      }
      if(cb) {
        cb(res);
      }

      this.loading = false;
    });
  }

  //Todo loadSingleTopicByRefId()
  loadSingleTopicByRefId(cb) {
    if(!this.selectedAssignmentId) return false;

    this.loading = true;
    const params = new URLSearchParams();
    params.append('messages_embedded', this.messagesEmbedded);
    params.append('ref_type', this.refType);

    return this.api.get(`/messenger/topics_by_ref/${this.selectedAssignmentId}?${params}`).then(res => {
      if(res.status === 200 || res.ok) {
        this.messengers = res.data.messages;
        if(this.topicSelected && this.topicSelected.unviewed_messages_count) {
          this.markedAllViewed = false;
        }
      }
      if(cb) {
        cb(res);
      }

      this.loading = false;
    });
  }

  //Todo loadTopics()
  loadTopics(cb) {
    this.topicsLoading = true;

    let params = new URLSearchParams();

    Object.keys(this.topicsFilter).map(t => {
      params.append(t, this.topicsFilter[t]);
    });

    this.api.get(`/messenger/topics_by_ref`, params).then(res => {
      if(res.status === 200 || res.ok) {
        let topics = [];
        let _topics = res.data || [];
        _topics = _.groupBy(_topics, (topic) => topic.unviewed_messages_count > 0 ? 0 : 1);
        Object.values(_topics).map(t => {
          let _t = [];
          _t = _.orderBy(t, Object.keys(this.topicsOderBy), Object.values(this.topicsOderBy));
          topics = topics.concat(_t);
        });

        this.topics = topics;
      }

      if(cb) cb(res);

      this.topicsLoading = false;
    });
  }

  topicQuery = {
    messages_embedded: false
  };

  //Todo setTopicQuery()
  setTopicQuery() {}
  //Todo loadTopic()
  loadTopic(id = null, cb = () => {}) {
    if(!id) return null;

    this.api.get(`/messenger/topics/${id}`).then(res => {
      if(cb) cb(res);

      if(res.ok || res.status === 200) {

      }
    });
  }

  // [Messenger] -Paginated- Messages Inside a Topic
  //Todo loadMessageByTopicId()
  loadMessageByTopicId(topicId, cb) {
    this.loading = true;
    const arrayParams = {
      start_ts: this.startDate,
      end_ts: this.endDate,
      // page_number: this.pageNumber,
      // page_size: this.pageSize,
    };
    const searchParams = new URLSearchParams(arrayParams);
    const params = searchParams.toString();

    return this.api.get(`/messenger/topics/${topicId}/messages?` + params).then(res => {
      if(res.status === 200 || res.ok) {
        this.messengers = res.data;
        // this.markedAllViewed = false;
      } else {
        this.messengers = [];
      }

      if(cb) {
        cb(res);
      }

      this.loading = false;
    });
  }

  //Todo generateTopic()
  generateTopic(refId, cb) {
    this.creatingTopic = true;
    this.topicLoading = true;
    const params = new URLSearchParams();
    params.append('ref_id', refId);
    params.append('ref_type', this.refType);

    this.api.post(`/messenger/topics?${params}`).then(res => {
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
      }
      if(cb) {
        cb(res);
      }
      this.creatingTopic = false;
      this.topicLoading = false;
    });
  }

  async postMessage(body, cb) {
    this.posting = true;
    const message = body.body;

    const { sourceLanguage, targetLanguage, enableTranslate } = this.translateOption;
    if (enableTranslate) {
      try {
        const driver = this.selectedDriver;
        const data = { source_message: message, source_language: sourceLanguage, target_language: targetLanguage, receiver: '' };
        if (driver) data.receiver = `DR_${driver.id}`;
        const response = await this.api.post('messenger/translate', data);
        if (response.status === 200) body.body = response.data;
      } catch {}
    }

    this.api.post(`/messenger/messages`, body).then(res => {
      if(res.status === 200 || res.ok) {
        this.lastMessage = res.data;
      }
      if(cb) {
        cb(res);
      }
      this.posting = false;
    });
  }

  //Todo loadTopicByAssignmentId()
  loadTopicByAssignmentId(assignmentId, cb) {
    if(!assignmentId) return false;

    this.topicLoading = true;
    const params = new URLSearchParams();
    params.append('messages_embedded', this.messagesEmbedded);
    params.append('ref_type', this.refType);

    this.api.get(`/messenger/topics_by_ref/${assignmentId}?${params}`).then(res => {
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
      } else {
        this.topicSelected = null;
        this.topicSelectedId = null;
      }
      if(cb) {
        cb(res)
      }
      this.topicLoading = false;
    });
  }

  //Todo loadSingleTopic()
  loadSingleTopic(cb) {
    if(!this.topicSelectedId) return;

    this.topicLoading = true;
    const params = new URLSearchParams();
    params.append('messages_embedded', this.messagesEmbedded);

    this.api.get(`/messenger/topics/${this.topicSelectedId}?${params}`).then(res => {
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
      }
      if(cb) {
        cb(res)
      }

      this.topicLoading = false;
    });
  }

  //Todo reloadMessages()
  reloadMessages() {
    if(!this.topicSelected) return;

    this.loadMessageByTopicId(this.topicSelected.id)
  }

  //Todo uploadFile()
  uploadFile(files, cb) {
    if(!files || !files.length) return false;

    this.uploading = true;
    const formData = new FormData();
    // Turn of multiple files uploaded
    // files.map(file => formData.append('files', files));
    // Limit single file.
    if(files.length < 1) return false;

    formData.append('file', files[0]);

    this.api.post(`/messenger/files`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if(res.status === 200 || res.ok) {
          this.filesUploaded = [res.data.unsigned_url];
        }
        if(cb) {
          cb(res);
        }
      this.uploading = false;
    });
  }

  removeUploadFiles() {
    this.filesUploaded = [];
  }

  //Todo follow()
  follow(cb) {
    if(!this.topicSelected || !this.topicSelected.id) return false;
    this.following = true;

    this.api.patch(`/messenger/topics/${this.topicSelected.id}/follow`).then(res => {
      if(cb) {
        cb(res);
      }
      if(res.status === 200 || res.ok) {
        this.isFollow = true;
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
      }

      this.following = false;
    });
  }

  //Todo forceFollow()
  forceFollow(followers, cb) {
    if(!followers.length || !this.topicSelectedId) return false;
    this.following = true;

    this.api.patch(`/messenger/topics/${this.topicSelectedId}/follow`, followers).then(res => {
      if(cb) {
        cb(res);
      }
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
      }

      this.following = false;
    });
  }

  //Todo forceUnfollow()
  forceUnfollow(unfollowers, cb) {
    if(!unfollowers.length || !this.topicSelectedId) return false;
    this.following = true;

    this.api.patch(`/messenger/topics/${this.topicSelectedId}/unfollow`, unfollowers).then(res => {
      if(cb) {
        cb(res);
      }
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
      }

      this.following = false;
    });
  }

  //Todo driverFollow()
  driverFollow(driverId, cb) {
    if(!driverId) return false;
    this.driverSeaching = true;

    this.api.get(`/drivers/search?q=id:${driverId}`)
      .then(res => {
        if (res.status === 200) {
          this.driverSearchResult = res.data;
          const userId = res.data.drivers.map(d => d.user_id)
          if(userId.length) {
            this.forceFollow(userId);
          }
        }
        if(cb) cb(res);

        this.driverSeaching = false;
      })
  }

  //Todo driverUnfollow()
  driverUnfollow(driverId, cb) {
    if(!driverId) return false;
    this.driverSeaching = true;

    this.api.get(`/drivers/search?q=id:${driverId}`)
      .then(res => {
        if (res.status === 200) {
          this.driverSearchResult = res.data;
          const userId = res.data.drivers.map(d => d.user_id)
          if(userId.length) {
            this.forceUnfollow(userId);
          }
        }
        if(cb) cb(res);

        this.driverSeaching = false;
      })
  }

  //Todo unfollow()
  unfollow(cb) {
    if(!this.topicSelected || !this.topicSelected.id) return false;
    this.following = true;

    this.api.patch(`/messenger/topics/${this.topicSelected.id}/unfollow`).then(res => {
      if(cb) {
        cb(res);
      }
      if(res.status === 200 || res.ok) {
        this.isFollow = false;
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
      }

      this.following = false;
    });
  }

  //Todo assignFollower()
  assignFollower(topicId, userIds, cb) {
    this.following = true;

    this.api.patch(`/messenger/topics/${topicId}/unfollow`, {
      "body": userIds
    }).then(res => {
      if(cb) {
        cb(res);
      }

      this.following = false;
    });
  }

  //Todo closeTopic()
  closeTopic(cb) {
    if(!this.topicSelected || !this.topicSelected.id) return false;
    this.closing = true;

    this.api.patch(`/messenger/topics/${this.topicSelected.id}/close`).then(res => {
      if(cb) cb(res);
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
        this.loadSingleTopic();
      }
    });

    this.closing = false;
  }

  //Todo openTopic()
  openTopic(cb) {
    if(!this.topicSelected || !this.topicSelected.id) return false;
    this.opening = true;

    this.api.patch(`/messenger/topics/${this.topicSelected.id}/open`).then(res => {
      if(cb) cb(res);
      if(res.status === 200 || res.ok) {
        this.topicSelected = res.data;
        this.topicSelectedId = this.topicSelected.id;
        this.loadSingleTopic();
      }
    });

    this.opening = false;
  }

  //Todo getDispatchers()
  getDispatchers(cb) {
    this.userListing = true;

    this.api.post(`/users/dispatchers`).then(res => {
      if(res.status === 200 || res.ok) {
        this.dispatchers = _.sortBy(res.data, [function(m){ return m.id }]);
      }
      if(cb) cb(res);
      this.userListing = false;
    })
  }

  //Todo getAdmins()
  getAdmins(cb) {
    this.userListing = true;
    this.api.post(`/users/admins`).then(res => {
      if(res.status === 200 || res.ok) {
        this.admins = _.sortBy(res.data, [function(m){ return m.id }]);
      }
      if(cb) cb(res);
      this.userListing = false;
    })
  }

  //Todo getDrivers()
  getDrivers(cb) {
    this.userListing = true;
    this.api.get(`/drivers/search?size=1000`).then(res => {
      if(res.status === 200 || res.ok) {
        const drivers = res.data.drivers.map(d => {
          d.id = d.user_id;
          delete d.user_id;
          return d;
        });

        this.drivers = _.sortBy(drivers, [function(m){ return m.id }]);
      }
      if(cb) cb(res);
      this.userListing = false;
    })
  }

  users = [];
  //Todo getAdminDispatcher
  getAdminDispatcher(cb) {
    this.userListing = true;

    this.api.post(`/users/admins_or_dispatchers`).then(res => {
      if(res.ok || res.status === 200) {
        this.users = res.data;
      }

      if(cb) cb(res);

      this.userListing = false;
    });
  }

  //Todo getDriverInfo()
  getDriverInfo(driverId = null, cb) {
    if(!driverId) return false;

    this.api.get(`/drivers/search?size=1&q=id:${driverId}`).then(res => {

      if(res.status === 200 || res.ok) {
        this.assignmentInfoInTopicSelected = {
          driver: res.data.drivers[0]
        }
      }

      if(cb) cb(res);
    })
  }

  loadingDriver = false;
  //Todo getDriverByIds
  getDriverByIds(ids = [], cb = () => {}) {
    if(!ids.length) return;

    this.loadingDriver = true;

    return this.api.post(`/users/drivers`, ids).then(res => {
      if((res.status === 200 || res.ok) && res.data.length) {
        // this.assignmentInfoInTopicSelected = Object.assign(this.assignmentInfoInTopicSelected, {['driver']: res.data[0].driver})
        this.loadingDriver = false;
      }

      if(cb) cb(res);
    });
  }

  //Todo setDate()
  setDate(dateTime) {
    if(!dateTime) return false;

    this.dateRanger = dateTime;
  }

  //Todo setmarkedAllViewed()
  setmarkedAllViewed(cb) {
    if(!this.messengers.length || this.markedAllViewed) return false;

    this.marking = true;
    const messengerIds = this.messengers.map(m => m.id);

    this.api.put(`/messenger/messages/mark_as_viewed`, messengerIds).then(res => {
      this.marking = false;
      if(res.status === 200 || res.ok) {
        this.markedAllViewed = true;
        this.messagesEmbedded = true;
        this.loadSingleTopicById()
      } else {
        this.markedAllViewed = false;
      }

      if(cb) cb(res);
    });
  }

  //Todo MarkedAllViewed no need Ids
  markAllViewed(topicId = null, cb) {
    if(!topicId && !this.topicSelectedId && !this.topicSelected) return false;

    const topic_id = topicId || this.topicSelectedId || this.topicSelected.id;

    this.marking = true;
    this.api.put(`messenger/topics/${topic_id}/mark_as_viewed`).then(res => {
      if(cb) cb(res);

      this.marking = false;

      if(res.ok || res.status === 204 || res.status === 200) {
        this.markedAllViewed = true;
      } else {
        this.markedAllViewed = false;
      }
    });
  }

  //Todo assignmentConverstationSummary()
  assignmentConverstationSummary(params = [], cb) {
    this.assignmentSummaryLoading = true;

    const assignmentSummaries = this.api.post(`/messenger/assignment_conversation/summary`, params).then(res => {
      if(res.status === 200 || res.ok) {
        this.assignmentSummaries = res.data;
      }

      this.assignmentSummaryLoading = false;

      if(cb) cb(res);

      return res.data;
    });

    return assignmentSummaries;
  }

  set_AAS_Query_filter(value) {
    this.AASummeriesQuery = value;
  }

  get_AAS_Query_filter() {
    return this.AASummeriesQuery;
  }

  setActiveAssignmentQuery(value) {
    this.AA_Query = value;
  }

  getActiveAssignmentQuery() {
    return this.AA_Query;
  }

  //Todo activeAssignmentSummery()
  activeAssignmentSummery(cb) {
    this.loadingactiveAssignmentSummaries = true;
    const query = this.get_AAS_Query_filter();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/active_assignment_conversation/summary`, params).then(res => {
      if(cb) cb(res);

      if(res.status === 200 || res.ok) {
        this.activeAssignmentSummaries = Object.assign(this.activeAssignmentSummaries, {[query.section]: res.data});
      }

      this.loadingactiveAssignmentSummaries = false;
    });
  }

  assignmentLoading = false;

  async getAssignmentInfo(assignmentId = null, cb) {

    if(!assignmentId && !this.selectedAssignmentId) return false;

    this.assignmentLoading = true;

    const assignment_id = assignmentId || this.selectedAssignmentId;

    await this.api.get(`/assignments/${assignment_id}/detail?show_soft_deleted=true`).then(async (res) => {
      if(res.status === 200 || res.ok) {
        if(res.data) {
          this.assignmentInfoInTopicSelected = await this.processAssignmentDetail(res.data);
        }
        this.assignmentLoading = false;
      }
      if(cb) cb(res);
    })
  }

  //Todo getFollowActiveAssignment
  getFollowActiveAssignment(cb) {
    this.AA_FollowLoading = true;

    const query = this.getActiveAssignmentQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/extract/active/following`, params).then(res => {
      if(cb) cb(res);

      if(res.data === 200 || res.ok) {
        this.AA_Following = res.data;
      }

      this.AA_FollowLoading = false;
    });
  }

  //Todo getAttendedActiveAssignment
  getAttendedActiveAssignment(cb) {
    this.AA_AttendedLoading = true;

    const query = this.getActiveAssignmentQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/extract/active/attended`, params).then(res => {
      if(cb) cb(res);

      if(res.data === 200 || res.ok) {
        this.AA_Attended = res.data;
      }

      this.AA_AttendedLoading = false;
    });
  }

  //Todo getUnattendedActiveAssignment
  getReadUnattendedActiveAssignment(cb) {
    this.AA_ReadUnattendedLoading = true;

    const query = this.getActiveAssignmentQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/extract/active/unattended/read`, params).then(res => {
      if(cb) cb(res);

      if(res.data === 200 || res.ok) {
        this.AA_ReadUnattended = res.data;
      }

      this.AA_ReadUnattendedLoading = false;
    });
  }

  //Todo getUnattendedActiveAssignment
  getUnreadUnattendedActiveAssignment(cb) {
    this.AA_UnreadUnattendedLoading = true;

    const query = this.getActiveAssignmentQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/extract/active/unattended/unread`, params).then(res => {
      if(cb) cb(res);

      if(res.data === 200 || res.ok) {
        this.AA_UnreadUnattended = res.data;
      }

      this.AA_UnreadUnattendedLoading = false;
    });
  }

  //Todo getUnattendedActiveAssignment
  getAllUnattendedActiveAssignment(cb) {
    this.AA_UnattendedLoading = true;

    const query = this.getActiveAssignmentQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/extract/active/unattended/all`, params).then(res => {
      if(cb) cb(res);

      if(res.data === 200 || res.ok) {
        this.AA_Unattended = res.data;
      }

      this.AA_UnattendedLoading = false;
    });
  }

  // General Solving
  generalSolving = [];
  generalSolvingLoading = false;
  // General Solved
  generalSolved = [];
  generalSolvedLoading = false;
  // General Unsolved
  generalUnsolve = [];
  generalUnsolveLoading = false;
  // Query
  GS_Query = {
    'start_ts': '',
    'end_ts': '',
    'page_number': 1,
    'page_size': 20,
  };

  //Todo setGeneralSupportQuery
  setGeneralSupportQuery(query) {
    this.GS_Query = Object.assign(this.GS_Query, query);
  }

  //Todo getGeneralSupportQuery
  getGeneralSupportQuery() {
    return this.GS_Query;
  }

  //Todo generalLoadSolving
  getGeneralSolving(cb) {
    this.generalSolvingLoading = true;

    const query = this.getGeneralSupportQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/extract/general/solving`, query).then(res => {
      if(res.ok || res.status === 200) {
        this.generalSolving = res.data;

        if(cb) cb(res);
      }

      this.generalSolvingLoading = false;
    });
  }

  //Todo generalLoadSolved
  getGeneralSolved(cb) {
    this.generalSolvedLoading = true;

    const query = this.getGeneralSupportQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    return this.api.get(`/messenger/topics/extract/general/solved`, params).then(res => {
      if(res.ok || res.status === 200) {
        this.generalSolved = res.data;

        if(cb) cb(res);
      }

      this.generalSolvedLoading = false;

      return res;
    }).then(res => res);
  }

  //Todo generalLoadSolving
  getGeneralUnsolved(cb) {
    this.generalUnsolveLoading = true;

    const query = this.getGeneralSupportQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/extract/general/unsolved`, params).then(res => {
      if(res.ok || res.status === 200) {
        this.generalSolving = res.data;

        if(cb) cb(res);
      }

      this.generalUnsolveLoading = false;
    });
  }

  solving = false;

  //Todo solve
  solve(topicId = null, cb = () => {}) {
    if(!topicId || !this.topicSelectedId) return false;

    this.solving = true;

    this.api.patch(`/messenger/topics/${topicId}/solve`).then(res => {
      if(res.ok || res.status === 200) {
        this.topicSelected = res.data;
        if(cb) cb(res);
      }

      this.solving = false;
    })
  }

  //Todo solve
  unsolve(topicId = null, cb = () => {}) {
    if(!topicId || !this.topicSelectedId) return false;

    this.solving = true;

    this.api.patch(`/messenger/topics/${topicId}/unsolve`).then(res => {
      if(res.ok || res.status === 200) {
        this.topicSelected = res.data;
        if(cb) cb(res);
      }

      this.solving = false;
    })
  }

  //Todo loadTopic
  externaLloadTopic(topicId = null, cb = () => {}) {
    this.api.get(`/messenger/topics/${topicId}`).then(res => {
      if(cb) cb(res);
    });
  }

  // General support search
  generalSearching = false;
  generalSearchQuery = {
    q: 'id:2',
    status: 'OPEN',
    ref_type: 'DRIVER_GENERAL_SUPPORT'
  };

  //Todo setGeneralSearchQuery
  setGeneralSearchQuery(value) {
    this.generalSearchQuery = value;
  }

  //Todo getGeneralSearchQuery
  getGeneralSearchQuery(value) {
    return this.generalSearchQuery;
  }

  //Todo generalSearch()
  generalSearch(cb) {
    const query = this.getGeneralSearchQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/search`, params).then(res => {
      if(cb) cb(res);
    });
  }

  // Active assignment search
  activeSearching = false;
  activeSearchQuery = {
    q: 'id:186244',
    status: 'OPEN',
    ref_type: 'ASSIGNMENT_CONVERSATION'
  };

  //Todo setGeneralSearchQuery
  setActiveSearchQuery(value) {
    this.activeSearchQuery = value;
  }

  //Todo getGeneralSearchQuery
  getActiveSearchQuery(value) {
    return this.activeSearchQuery;
  }

  //Todo generalSearch()
  activeSearch(cb) {
    const query = this.getActiveSearchQuery();

    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/topics/search`, params).then(res => {
      if(cb) cb(res);
    });
  }

  logging = false;
  activities = [];
  activityQuery = {
    topic_id: null
  };

  //Todo activityLogs()
  getActivityLogs(topicId = null, cb = () => {}) {
    if(!topicId && !this.topicSelectedId) return;

    this.logging = true;

    const query = {
      topic_id: topicId
    };
    let params = new URLSearchParams();
    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    this.api.get(`/messenger/activity_logs`, params).then(res => {
      if(cb) cb(res);

      if(res.ok || res.status === 200) {
        this.activities = res.data;
      }
    })
  }

  showingStopTypes = ['DROP_OFF', 'RETURN'];
  filter = null;

  get filteredShowingStops() {
    if (!this.assignmentInfoInTopicSelected || !this.assignmentInfoInTopicSelected.stops)
      return []

    const stops = this.assignmentInfoInTopicSelected.stops
      .filter(s => this.showingStopTypes.indexOf(s.type) >= 0)

    // filter
    if (this.filter) {
      const searchFields = [
        "label.driver_label",
        "shipment.customer.name",
        "shipment.customer.phone_number",
        "shipment.customer.email",
        "shipment.dropoff_address.street",
        "shipment.dropoff_address.city",
        "shipment.dropoff_address.state",
        "shipment.dropoff_address.zipcode",
        "shipment.internal_id",
        "shipment.delivery_items",
        "shipment.tracking_code",
      ];
      return stops.filter(s => searchInObject(s, this.filter, "i", searchFields))
    }

    return stops;
  }

  stopSelected = null;

  setStopSelected = (stop) => {
    this.stopSelected = stop;
  };

  addressSearchResults = [];
  addressSearchFilter = {
    "from": 0,
    "size": 10,
    "q": "",
    "filters": {},
    "sorts": [
      "-dropoff_earliest_ts"
    ]
  };

  searchAddress(cb) {
    const keywordIgnoreWord = this.addressSearchFilter.q.replaceAll(/[^a-zA-Z0-9-\s]/ig, '');
    const params = Object.assign({}, this.addressSearchFilter, {q: keywordIgnoreWord});

    this.api.post(`/shipments/search`, params).then(res => {
      if(res.status === 200 && res.ok && res.data && res.data.results) {
        this.addressSearchResults = res.data.results;
      }
      if(cb)
        cb(res);
    })
  }

  pods = [];
  loadPodsByStop(cb) {
    if(!this.stopSelected) return;

    this.api.get(`/stops/${this.stopSelected.id}/pod`).then(res => {
      if(res.status === 200 && res.ok && res.data) {
        this.pods = res.data;
      }
    });
  }

  sendLink({title = null, url = null}, cb) {
    if(!this.topicSelectedId || !title || !url) return;

    const body = { title, url, topic_id: this.topicSelectedId };

    this.api.post(`/messenger/messages/share_link?minified=false`, body).then(res => {
      if(cb) cb(res)
    })
  }

  removeMessage(messageId, cb) {
    if (!messageId) return;

    this.api.delete(`/messenger/messages/${messageId}`).then(res => {
      if(cb) cb(res)
    });
  }

  get selectedDriver() {
    return _.get(this.assignmentInfoInTopicSelected, 'driver');
  }

  setTranslateOption(option) {
    const updated = { ...this.translateOption, ...option };
    const selectedDriver = this.selectedDriver;

    this.translateOption = updated;
    if (selectedDriver) this.translateOptionMap[selectedDriver.id] = updated.enableTranslate;
  }

  resetTranslateOption() {
    this.translateOption = DEFAULT_TRANSLATE_OPTION;
  }

  setSelectedAssignment(assignment) {
    this.assignmentInfoInTopicSelected = assignment;
  }
}

export default MessengerStore;
